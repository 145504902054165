import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import type { RepeatableActionTemplateModel } from '../../../models/RepeatableActionTemplateModel'
import ConditionKeywordsService from '../../../services/ConditionKeywordsService'

export const RepeatableActionTemplateTableModalComponent: FC<{
  data?: any
  columns: any[]
  onClose: () => void
  onSubmit: (values: RepeatableActionTemplateModel) => void
  open: boolean
}> = ({
  data,
  open,
  columns,
  onClose,
  onSubmit,
}) => {
  const [values, setValues] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? '']
                = data != null ? data.original[column.accessorKey] : ''
      return acc
    }, {} as any),
  )
  const conditionKeywordsService: ConditionKeywordsService
        = new ConditionKeywordsService()

  useEffect(() => {
    setValues(
      columns.reduce((acc, column) => {
        acc[column.accessorKey ?? '']
                    = data != null ? data.original[column.accessorKey] : ''
        return acc
      }, {} as any),
    )
  }, [data])

  const handleSubmit = () => {
    // put your validation logic here
    onSubmit(values)
    onClose()
  }

  return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">
                {data == null ? 'Vytvorenie novej kategórie' : 'Upraviť kategóriu'}
            </DialogTitle>
            <DialogContent>
                <form onSubmit={e => e.preventDefault()}>
                    <Stack
                        sx={{
                          width: '100%',
                          minWidth: {
                            xs: '300px',
                            sm: '360px',
                            md: '400px',
                          },
                          gap: '1.5rem',
                          marginTop: '10px',
                        }}
                    >
                        {columns
                          .filter(
                            (p: any) =>
                              p.accessorKey !== 'id' && p.accessorKey !== 'condition',
                          )
                          .map(column => (
                                <TextField
                                    multiline={true}
                                    minRows={1}
                                    key={column.accessorKey}
                                    label={column.header}
                                    name={column.accessorKey}
                                    value={values[column.accessorKey]}
                                    onChange={e =>
                                      setValues({
                                        ...values,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                />
                          ))}

                        <ReactTextareaAutocomplete
                            className="my-textarea"
                            loadingComponent={() => <span>Loading</span>}
                            value={values.condition}
                            onChange={e =>
                              setValues({
                                ...values,
                                condition: e.target.value,
                              })
                            }
                            trigger={
                                {
                                  ':': {
                                    dataProvider: async (name: string) => {
                                      return await conditionKeywordsService.fetch({ name })
                                    },
                                    component: ({ entity: { name } }: { entity: any }) => (
                                            <div>{`${name}`}</div>
                                    ),
                                    output: (item: { name: string }) => {
                                      return item.name
                                    },
                                  },
                                } as any
                            }
                            // textAreaComponent={InPo}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Zrušiť</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    Uložiť
                </Button>
            </DialogActions>
        </Dialog>
  )
}
