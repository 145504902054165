import { defaultApi } from '../constants'
import type { ContentWrapper } from '../models/ContentWrapper'
import type { ContentWrapperWithPage } from '../models/ContentWrapperWithPage'
import type { FetchParamsModel } from '../models/FetchParamsModel'
import type { NameCountModel } from '../models/NameCountModel'
import type { NamePriceModel } from '../models/NamePriceModel'
import type { NameQuantityModel } from '../models/NameQuantityModel'
import type { ReceiptItemModel } from '../models/ReceiptItemModel'
import UtilService from './UtilService'

export default class ReceiptItemService {
  async fetch(
    fetchParamsModel: FetchParamsModel,
  ): Promise<ContentWrapperWithPage<ReceiptItemModel>> {
    const { data } = await defaultApi.get(
      'receiptItems/search/findByContainingFilter',
      {
        params: UtilService.getParams(fetchParamsModel),
      },
    )
    return data
  }

  async getTopItemCount({
    dateFrom,
                              dateTo,
                              numberOfTop,
  }: {
    dateFrom?: Date
    dateTo?: Date
    numberOfTop?: number
  }): Promise<ContentWrapper<NameCountModel>> {
    const params: {
      dateFrom?: string
      dateTo?: string
      size?: number
    } = {}

    if (dateFrom) {
      params.dateFrom = UtilService.getDateToFilter(dateFrom)
    }
    if (dateTo) {
      params.dateTo = UtilService.getDateToFilter(dateTo)
    }
    if (numberOfTop) {
      params.size = numberOfTop
    }

    const { data } = await defaultApi.get(
      'receiptItems/search/getTopItemCount',
      {
        params,
      },
    )
    return data
  }

  async getTopItemNamePrice({
    dateFrom,
                                  dateTo,
                                  numberOfTop,
  }: {
    dateFrom?: Date
    dateTo?: Date
    numberOfTop?: number
  }): Promise<ContentWrapper<NamePriceModel>> {
    const params: {
      dateFrom?: string
      dateTo?: string
      size?: number
    } = {}

    if (dateFrom) {
      params.dateFrom = UtilService.getDateToFilter(dateFrom)
    }
    if (dateTo) {
      params.dateTo = UtilService.getDateToFilter(dateTo)
    }
    if (numberOfTop) {
      params.size = numberOfTop
    }

    const { data } = await defaultApi.get(
      'receiptItems/search/getTopItemNamePrice',
      {
        params,
      },
    )
    return data
  }

  async getTopItemNameQuantity({
    dateFrom,
                                     dateTo,
                                     numberOfTop,
  }: {
    dateFrom?: Date
    dateTo?: Date
    numberOfTop?: number
  }): Promise<ContentWrapper<NameQuantityModel>> {
    const params: {
      dateFrom?: string
      dateTo?: string
      size?: number
    } = {}

    if (dateFrom) {
      params.dateFrom = UtilService.getDateToFilter(dateFrom)
    }
    if (dateTo) {
      params.dateTo = UtilService.getDateToFilter(dateTo)
    }
    if (numberOfTop) {
      params.size = numberOfTop
    }

    const { data } = await defaultApi.get(
      'receiptItems/search/getTopItemNameQuantity',
      {
        params,
      },
    )
    return data
  }
}
