import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Box } from '../../components/Box'
import { CategoryTableComponent } from './components/CategoryTableComponent'

export const Category: React.FC = () => {
  return (
        <div className="content-wrapper">
            <section className="content">
                <div className="container-fluid">
                    <Row>
                        <Col md={12}>
                            <Box
                                loaded={true}
                                title={
                                    <div>
                                        <i className="fas fa-table mr-1"/>
                                        Kategórie
                                    </div>
                                }
                                isTable={true}
                            >
                                <div className="box-overflow" style={{ minHeight: '212px' }}>
                                    <CategoryTableComponent/>
                                </div>
                            </Box>
                        </Col>
                    </Row>
                </div>
            </section>
        </div>
  )
}

export default Category
