import {Edit} from '@mui/icons-material'
import RefreshIcon from '@mui/icons-material/Refresh'
import {Alert, Box, IconButton, Snackbar, Tooltip} from '@mui/material'
import type {
    ColumnFiltersState,
    ColumnOrderState,
    ColumnPinningState,
    ColumnSizingState,
    SortingState,
    VisibilityState,
} from '@tanstack/react-table'
import {MaterialReactTable} from "material-react-table";
import type {MRT_ColumnDef} from 'material-react-table'
import React, {useEffect, useMemo, useState} from 'react'
import {useRecoilState} from "recoil";
import {userSettingsState} from "../../../atoms/modelAtoms";
import {TableComponentStateEnum} from "../../../enums/TableComponentStateEnum";
import type {AccountModel} from '../../../models/AccountModel'
import type {ContentWrapperWithPage} from '../../../models/ContentWrapperWithPage'
import AccountService from '../../../services/AccountService'
import UserAppSettingService from '../../../services/UserAppSettingService'
import UtilService from '../../../services/UtilService'
import {AccountTableModalComponent} from './AccountTableModalComponent'

export const AccountTableComponent: React.FC<{
    refreshTime?: number
}> = ({refreshTime}) => {
    const [data, setData] = useState<ContentWrapperWithPage<AccountModel>>(
        UtilService.initContentWrapperWithPage<AccountModel>(),
    )
    const [userSettings, setUserSettings] = useRecoilState(userSettingsState);
    const [componentState, setComponentState] = useState<TableComponentStateEnum>(TableComponentStateEnum.LOADING)

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [globalFilter, setGlobalFilter] = useState('')
    const [sorting, setSorting] = useState<SortingState>([])
    const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
        left: [],
        right: [],
    })
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
    const [columnSizing, setColumnSizing] = useState<ColumnSizingState>({})
    const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 50,
    })
    const [dataEdit, setDataEdit] = useState<any>(null)
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const accountService: AccountService = new AccountService()
    const userAppSettingService: UserAppSettingService
        = new UserAppSettingService()

    const getData = async () => {
        if (!data.content.length) {
            setComponentState(TableComponentStateEnum.LOADING);
        } else {
            setComponentState(TableComponentStateEnum.REFRESHING);
        }

        try {
            setData(
                await accountService.fetch({
                    page: pagination.pageIndex,
                    size: pagination.pageSize,
                    globalFilter,
                    columnFiltersState: columnFilters,
                    sort: sorting.length > 0 ? sorting[0] : undefined,
                }),
            )
            setComponentState(TableComponentStateEnum.SUCCESS);
        } catch (e) {
            setComponentState(TableComponentStateEnum.ERROR);
            console.error(e)
            return
        }

        if (refreshTime !== null && refreshTime !== undefined && refreshTime > 0) {
            setTimeout(() => getData(), refreshTime)
        }
    }

    const handleRow = async (values: AccountModel) => {
        await accountService.update(values)
        await getData()
    }

    const columns = useMemo<MRT_ColumnDef<AccountModel>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'Id',
            },
            {
                accessorKey: 'name',
                header: 'Názov',
            },
            {
                accessorKey: 'iban',
                header: 'IBAN',
            },
            {
                accessorKey: 'bic',
                header: 'BIC',
            },
            {
                accessorKey: 'street',
                header: 'Ulica',
            },
            {
                accessorKey: 'town',
                header: 'Mesto',
            },
            {
                accessorKey: 'country',
                header: 'Štát',
            },
            {
                accessorKey: 'type',
                header: 'Typ',
            },
            {
                accessorKey: 'accId',
                header: 'AccId',
            },
            {
                accessorKey: 'customName',
                header: 'Vlastné meno',
            },
        ],
        [],
    )

    useEffect(() => {
        setUserSettings({
            page: 'accountTable',
            columnSizingState: columnSizing,
            columnFiltersState: columnFilters,
            columnPinningState: columnPinning,
            sortingState: sorting,
            visibilityState: columnVisibility,
            columnOrderState: columnOrder,
            globalFilter,
        });
    }, [
        columnSizing,
        columnFilters,
        columnPinning,
        sorting,
        columnVisibility,
        columnOrder,
        globalFilter,
    ])

    useEffect(() => {
        const fetchUserAppSettings = () => {
            userAppSettingService
                .fetch({page: 'accountTable'})
                .then((saveState) => {
                    setColumnPinning(saveState.columnPinningState)
                    setColumnSizing(saveState.columnSizingState)
                    setColumnVisibility(saveState.visibilityState)
                    setColumnFilters(saveState.columnFiltersState)
                    setSorting(saveState.sortingState)
                    setColumnOrder(saveState.columnOrderState)
                    setGlobalFilter(saveState.globalFilter)
                })
        }
        fetchUserAppSettings()
    }, [])

    useEffect(() => {
        getData().then()
    }, [
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
    ])

    return (
        <div>
            <MaterialReactTable
                initialState={{
                    showColumnFilters: false,
                    density: 'compact',
                }}
                state={{
                    columnFilters,
                    globalFilter,
                    isLoading: componentState === TableComponentStateEnum.LOADING,
                    pagination,
                    showAlertBanner: componentState === TableComponentStateEnum.ERROR,
                    showProgressBars: componentState === TableComponentStateEnum.REFRESHING,
                    sorting,
                    columnPinning,
                    columnSizing,
                    columnVisibility,
                    columnOrder,
                }}
                data={data.page.totalElements > 0 ? data.content : []}
                rowCount={data.page.totalElements}
                columns={columns}
                manualFiltering
                manualPagination
                manualSorting
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                onColumnPinningChange={setColumnPinning}
                onColumnSizingChange={setColumnSizing}
                onColumnVisibilityChange={setColumnVisibility}
                onColumnOrderChange={setColumnOrder}
                enableEditing
                enableColumnOrdering
                enableStickyHeader
                enableStickyFooter
                enableColumnResizing
                enablePinning
                renderRowActions={({row}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '1rem',
                    }}
                    >
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton
                                onClick={async () => {
                                    await setDataEdit(row)
                                    await setCreateModalOpen(true)
                                }}
                            >
                                <Edit/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={() => {
                    return (
                        <div style={{display: 'inline'}}>
                            <Tooltip arrow title="Refresh Data">
                                <IconButton onClick={() => getData()}>
                                    <RefreshIcon/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                }}
                muiTableContainerProps={{sx: {maxHeight: '65vh'}}}
                columnResizeMode="onChange"
                muiPaginationProps={{
                    rowsPerPageOptions: [50, 100, 200],
                }}
                muiToolbarAlertBannerProps={
                    componentState === TableComponentStateEnum.ERROR
                        ? {
                            color: 'error',
                            children: 'Error loading data',
                        }
                        : undefined
                }
            ></MaterialReactTable>
            <AccountTableModalComponent
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleRow}
                data={dataEdit}
            />
        </div>
    )
}
