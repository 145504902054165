import React, { useEffect, useState } from 'react'
import { Bar, BarChart, Brush, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import TransactionService from '../../../services/TransactionService'

export const CreditDebitChartComponent: React.FC<{
  loaded: any
  onlyFirstTimeShowLoading?: boolean
  refreshTime?: number
  onChangeData?: any
  loadDataOn: boolean
  dataIn?: any
}> = ({
  loaded,
  onlyFirstTimeShowLoading = false,
  refreshTime,
  onChangeData,
  loadDataOn,
  dataIn,
}) => {
  let timeout: any

  const [data, setData] = useState<any>([])

  useEffect(() => {
    let firstTimeLoaded = true
    const transactionService: TransactionService = new TransactionService()

    async function getData() {
      if (firstTimeLoaded || !onlyFirstTimeShowLoading) {
        loaded(false)
      }

      try {
        const _data = await transactionService.getMonthDateCreditDebitTotal({})
        setData(_data.content)
        onChangeData(_data.content)
      } catch (e) {
        console.error(e)
      }

      loaded(true)
      firstTimeLoaded = false

      if (
        refreshTime !== null
                && refreshTime !== undefined
                && refreshTime > 0
      ) {
        timeout = setTimeout(() => getData(), refreshTime)
      }
    }

    if (loadDataOn) {
      getData()
    } else {
      if (firstTimeLoaded || !onlyFirstTimeShowLoading) {
        loaded(false)
      }
      setData(dataIn)
      loaded(true)
    }
  }, [dataIn])

  useEffect(() => {
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
        <ResponsiveContainer aspect={2.5}>
            <BarChart
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="date"/>
                <YAxis/>
                <Tooltip/>
                <Legend/>
                <Bar dataKey="credit" fill="#8884d8"/>
                <Bar dataKey="debit" fill="#82ca9d"/>
                <Brush dataKey="date" height={30} stroke="#8884d8"/>
            </BarChart>
        </ResponsiveContainer>
  )
}
