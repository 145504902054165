import React from 'react'
import { Header } from './Header'
import { Menu } from './Menu'

export const AdminLTE: React.FC<{
  children: React.ReactElement

}> = ({ children }) => {
  return (
        <div>
            <div className="wrapper">
                <Header/>
                <Menu></Menu>
            </div>
            {children}
        </div>
  )
}
