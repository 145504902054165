import { Html5QrcodeScanner } from 'html5-qrcode';
import {Html5QrcodeScannerConfig} from "html5-qrcode/src/html5-qrcode-scanner";
import React, { useEffect } from 'react';
import {v4 as uuidv4} from 'uuid';

export interface QrCodeScannerConfig {
    fps?: number;
    qrbox?: number;
    aspectRatio?: number;
    disableFlip?: boolean;
    qrCodeSuccessCallback: any;
    qrCodeErrorCallback: any;
    verbose?: boolean;
}

const createConfig = (props: QrCodeScannerConfig):Html5QrcodeScannerConfig => {
    return {
        fps: props.fps ||  10,
        qrbox: props.qrbox || 250,
        aspectRatio: props.aspectRatio || 1.0,
        disableFlip: props.disableFlip || false,
    };
};

export const QRCodeScannerComponent: React.FC<QrCodeScannerConfig> = ({...props}) => {

    const id = uuidv4();

    useEffect(() => {
        const config = createConfig(props);
        const verbose = props.verbose === true;
        const html5QrcodeScanner = new Html5QrcodeScanner(id, config, verbose);
        html5QrcodeScanner.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback);
        return () => {
            html5QrcodeScanner.clear().catch(error => {
                console.error("Failed to clear html5QrcodeScanner. ", error);
            });
        };
    }, []);

    return (<div id={id} />);
};
