import { defaultApi } from '../constants'
import type { ContentWrapperWithPage } from '../models/ContentWrapperWithPage'
import type { FetchParamsModel } from '../models/FetchParamsModel'
import type { RepeatableActionTemplateModel } from '../models/RepeatableActionTemplateModel'
import UtilService from './UtilService'

export default class RepeatableActionTemplateService {
  async fetch(fetchParamsModel: FetchParamsModel): Promise<ContentWrapperWithPage<RepeatableActionTemplateModel>> {
    const { data } = await defaultApi.get('repeatableActionTemplates', {
      params: UtilService.getParams(fetchParamsModel),
    })
    return data
  }

  async create(repeatableActionTemplate: RepeatableActionTemplateModel) {
    const { data } = await defaultApi.post('repeatableActionTemplates', repeatableActionTemplate)
    return data
  }

  async update(repeatableActionTemplate: RepeatableActionTemplateModel) {
    const { data } = await defaultApi.put(`repeatableActionTemplates/${repeatableActionTemplate.id}`, repeatableActionTemplate)
    return data
  }

  async delete(id: number) {
    const { data } = await defaultApi.delete(`repeatableActionTemplates/${id}`)
    return data
  }
}
