import axios from 'axios'
import {getToken} from "./services/auth-util";

export const DATA_API_BASE_URL = process.env.REACT_APP_DATA_API_BASE_URL

export const defaultApi = axios.create({
    baseURL: DATA_API_BASE_URL
})

defaultApi.interceptors.request.use(
    async request => {
        // Do something before request is sent

        console.log('intercepting request') ;

        request.headers["Authorization"] = "Bearer " + await getToken();
        request.headers["Content-Type"] = 'application/json'
        return request;
    },
    (error: any) => {
        Promise.reject(error);
    }
);
