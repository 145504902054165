import type {
  ColumnFiltersState,
  ColumnOrderState,
  ColumnPinningState,
  ColumnSizingState,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table'
import { defaultApi } from '../constants'
import type { ContentWrapper } from '../models/ContentWrapper'
import type { UserAppSettingModel } from '../models/UserAppSettingModel'
import {UserSettingsInput} from "../models/UserSettingsInput";

export default class UserAppSettingService {
  async fetch(input: { page: string }): Promise<{
    columnFiltersState: ColumnFiltersState
    sortingState: SortingState
    columnPinningState: ColumnPinningState
    visibilityState: VisibilityState
    columnSizingState: ColumnSizingState
    columnOrderState: ColumnOrderState
    globalFilter: string
  }> {
    const { data }: { data: ContentWrapper<UserAppSettingModel> }
            = await defaultApi.get(`userAppSettings/${input.page}/page`)

    const _columnFilterState = data.content.filter(
      p => p.keyParam === 'columnFiltersState',
    )
    const _columnPinningState = data.content.filter(
      p => p.keyParam === 'columnPinningState',
    )
    const _columnSizingState = data.content.filter(
      p => p.keyParam === 'columnSizingState',
    )
    const _sortingState = data.content.filter(
      p => p.keyParam === 'sortingState',
    )
    const _visibilityState = data.content.filter(
      p => p.keyParam === 'visibilityState',
    )
    const _columnOrderState = data.content.filter(
      p => p.keyParam === 'columnOrderState',
    )
    const _globalFilter = data.content.filter(
      p => p.keyParam === 'globalFilter',
    )

    return {
      columnFiltersState:
                _columnFilterState.length > 0
                  ? (JSON.parse(_columnFilterState[0].valueParam) as ColumnFiltersState)
                  : [],
      columnPinningState:
                _columnPinningState.length > 0
                  ? (JSON.parse(
                      _columnPinningState[0].valueParam,
                    ) as ColumnPinningState)
                  : {
                      left: [],
                      right: [],
                    },
      columnSizingState:
                _columnSizingState.length > 0
                  ? (JSON.parse(_columnSizingState[0].valueParam) as ColumnSizingState)
                  : {},
      sortingState:
                _sortingState.length > 0
                  ? (JSON.parse(_sortingState[0].valueParam) as SortingState)
                  : [],
      visibilityState:
                _visibilityState.length > 0
                  ? (JSON.parse(_visibilityState[0].valueParam) as VisibilityState)
                  : {},
      columnOrderState:
                _columnOrderState.length > 0
                  ? (JSON.parse(_columnOrderState[0].valueParam) as ColumnOrderState)
                  : [],
      globalFilter: _globalFilter.length > 0 ? _globalFilter[0].valueParam : '',
    }
  }

  async save(input: UserSettingsInput) {
    const userAppSettings: UserAppSettingModel[] = []
    if (input.columnFiltersState) {
      userAppSettings.push({
        keyParam: 'columnFiltersState',
        valueParam: JSON.stringify(input.columnFiltersState),
      })
    }
    if (input.sortingState) {
      userAppSettings.push({
        keyParam: 'sortingState',
        valueParam: JSON.stringify(input.sortingState),
      })
    }
    if (input.columnPinningState) {
      userAppSettings.push({
        keyParam: 'columnPinningState',
        valueParam: JSON.stringify(input.columnPinningState),
      })
    }
    if (input.visibilityState) {
      userAppSettings.push({
        keyParam: 'visibilityState',
        valueParam: JSON.stringify(input.visibilityState),
      })
    }
    if (input.columnSizingState) {
      userAppSettings.push({
        keyParam: 'columnSizingState',
        valueParam: JSON.stringify(input.columnSizingState),
      })
    }
    if (input.columnOrderState) {
      userAppSettings.push({
        keyParam: 'columnOrderState',
        valueParam: JSON.stringify(input.columnOrderState),
      })
    }
    if (input.globalFilter) {
      userAppSettings.push({
        keyParam: 'globalFilter',
        valueParam: input.globalFilter,
      })
    }

    if (userAppSettings.length > 0) {
      await defaultApi.post(
                `userAppSettings/${input.page}/page`,
                userAppSettings
      )
    } else {
      await defaultApi.delete(`userAppSettings/${input.page}/page`, {})
    }
  }
}
