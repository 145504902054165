import {debounce} from "@mui/material";
import React, {useCallback, useState} from 'react'
import {useRecoilState} from "recoil";
import {snackbarState} from "../../../atoms/modelAtoms";
import {QRCodeScannerComponent} from "../../../components/QRCodeScannerComponent";
import ReceiptService from '../../../services/ReceiptService'

export const QRCodeComponent: React.FC<{ onReadCompleted?: any }> = ({
                                                                         onReadCompleted,
                                                                     }) => {

    const [scanProcessing, setScanProcessing] = useState<boolean>(false)
    const [snackbar, setSnackbar] = useRecoilState(snackbarState);
    const receiptService: ReceiptService = new ReceiptService();

    async function handleScan(data: any) {
        if (data != null && !scanProcessing) {
            try {
                setScanProcessing(true)
                await receiptService.getByScan(data)
                onReadCompleted()
                setSnackbar({
                    message: 'Bloček bol pridaný',
                    severity: 'success',
                });
            } catch (e:any) {
                console.error(e)
                setSnackbar({
                    message: 'Pri pridávaní bločku sa vyskytla chyba: '+ (e?.response?.data?.message || ''),
                    severity: 'error',
                });
            } finally {
                setScanProcessing(false)
            }
        }
    }

    const debouncedHandleScan = useCallback(
        debounce(handleScan, 500)
        , []);

    return (
        <QRCodeScannerComponent
            qrCodeSuccessCallback={debouncedHandleScan}
            qrCodeErrorCallback={() => {
            }}
        />
    )
}
