import { defaultApi } from '../constants'
import type { CategoryModel } from '../models/CategoryModel'
import type { ContentWrapperWithPage } from '../models/ContentWrapperWithPage'
import type { FetchParamsModel } from '../models/FetchParamsModel'
import UtilService from './UtilService'

export default class CategoryService {
  async fetch(
    fetchParamsModel: FetchParamsModel,
  ): Promise<ContentWrapperWithPage<CategoryModel>> {
    const { data } = await defaultApi.get(
      'categories/search/findByContainingFilter',
      {
        params: UtilService.getParams(fetchParamsModel),
      },
    )
    return data
  }

  async create(category: CategoryModel) {
    const { data } = await defaultApi.post('categories', category)
    return data
  }

  async update(category: CategoryModel) {
    const { data } = await defaultApi.put(
            `categories/${category.id}`,
            category
    )
    return data
  }

  async delete(id: number) {
    const { data } = await defaultApi.delete(`categories/${id}`)
    return data
  }

  async remap() {
    const { data } = await defaultApi.post(
      'categories/remap'
    )
    return data
  }
}
