import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Box } from '../../components/Box'
import { AccountTableComponent } from './components/AccountTableComponent'

export const Account: React.FC = () => {
  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <Box
                loaded={true}
                title={
                  <div>
                    <i className="fas fa-table mr-1" />
                    Kategórie
                  </div>
                }
                isTable={true}
              >
                <div className="box-overflow" style={{ minHeight: '212px' }}>
                  <AccountTableComponent />
                </div>
              </Box>
            </Col>
          </Row>
        </div>
      </section>
    </div>
  )
}

export default Account
