import {AddBox, Delete, Edit} from '@mui/icons-material'
import AddLinkIcon from '@mui/icons-material/AddLink'
import RefreshIcon from '@mui/icons-material/Refresh'
import {Box, IconButton, Tooltip} from '@mui/material'
import type {
    ColumnFiltersState,
    ColumnOrderState,
    ColumnPinningState,
    ColumnSizingState,
    SortingState,
    VisibilityState,
} from '@tanstack/react-table'
import type {MRT_ColumnDef, MRT_Row} from 'material-react-table'
import {MaterialReactTable} from 'material-react-table'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useRecoilState} from "recoil";
import {snackbarState, userSettingsState} from "../../../atoms/modelAtoms";
import {TableComponentStateEnum} from "../../../enums/TableComponentStateEnum";
import type {CategoryModel} from '../../../models/CategoryModel'
import type {ContentWrapperWithPage} from '../../../models/ContentWrapperWithPage'
import CategoryService from '../../../services/CategoryService'
import UserAppSettingService from '../../../services/UserAppSettingService'
import UtilService from '../../../services/UtilService'
import {CategoryTableModalComponent} from './CategoryTableModalComponent'

export const CategoryTableComponent: React.FC<{
    refreshTime?: number
}> = ({refreshTime}) => {
    const [data, setData] = useState<ContentWrapperWithPage<CategoryModel>>(
        UtilService.initContentWrapperWithPage<CategoryModel>(),
    )
    const [userSettings, setUserSettings] = useRecoilState(userSettingsState);
    const [snackbar, setSnackbar] = useRecoilState(snackbarState);
    const [componentState, setComponentState] = useState<TableComponentStateEnum>(TableComponentStateEnum.LOADING)

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [globalFilter, setGlobalFilter] = useState('')
    const [sorting, setSorting] = useState<SortingState>([])
    const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
        left: [],
        right: [],
    })
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
    const [columnSizing, setColumnSizing] = useState<ColumnSizingState>({})
    const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 50,
    })
    const [dataEdit, setDataEdit] = useState<any>(null)
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const categoryService: CategoryService = new CategoryService()
    const userAppSettingService: UserAppSettingService
        = new UserAppSettingService()

    const getData = async () => {
        if (!data.content.length) {
            setComponentState(TableComponentStateEnum.LOADING);
        } else {
            setComponentState(TableComponentStateEnum.REFRESHING);
        }

        try {
            const _data = await categoryService.fetch({
                page: pagination.pageIndex,
                size: pagination.pageSize,
                globalFilter,
                columnFiltersState: columnFilters,
                sort: sorting.length > 0 ? sorting[0] : undefined,
            })
            setData(_data)
            setComponentState(TableComponentStateEnum.SUCCESS);
        } catch (e) {
            setComponentState(TableComponentStateEnum.ERROR);
            console.error(e)
            return
        }

        if (refreshTime !== null && refreshTime !== undefined && refreshTime > 0) {
            setTimeout(() => getData(), refreshTime)
        }
    }

    const handleRow = async (values: CategoryModel) => {
        if (values.id == null || values.id === 0) {
            await categoryService.create(values)
        } else {
            await categoryService.update(values)
        }
        await getData()
    }

    const handleDeleteRow = useCallback(async (row: MRT_Row<CategoryModel>) => {
        await categoryService.delete(row.getValue('id'))
        await getData()
    }, [])

    const columns = useMemo<MRT_ColumnDef<CategoryModel>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'Id',
            },
            {
                accessorKey: 'name',
                header: 'Názov',
            },
            {
                accessorKey: 'condition',
                header: 'Podmienka',
            },
            {
                accessorKey: 'priority',
                header: 'Priority',
            },
            {
                accessorKey: 'internalTransaction',
                header: 'Vnútorné prevody',
                filterVariant: 'checkbox',
                Cell: data => <div>{data.cell?.getValue() ? 'Áno' : 'Nie'}</div>,
            },
        ],
        [],
    )

    useEffect(() => {
        setUserSettings({
            page: 'categoryTable',
            columnSizingState: columnSizing,
            columnFiltersState: columnFilters,
            columnPinningState: columnPinning,
            sortingState: sorting,
            visibilityState: columnVisibility,
            columnOrderState: columnOrder,
            globalFilter,
        });
    }, [
        columnSizing,
        columnFilters,
        columnPinning,
        sorting,
        columnVisibility,
        columnOrder,
        globalFilter,
    ])

    useEffect(() => {
        const fetchUserAppSettings = () => {
            userAppSettingService
                .fetch({page: 'categoryTable'})
                .then((saveState) => {
                    setColumnPinning(saveState.columnPinningState)
                    setColumnSizing(saveState.columnSizingState)
                    setColumnVisibility(saveState.visibilityState)
                    setColumnFilters(saveState.columnFiltersState)
                    setSorting(saveState.sortingState)
                    setColumnOrder(saveState.columnOrderState)
                    setGlobalFilter(saveState.globalFilter)
                })
        }
        fetchUserAppSettings()
    }, [])

    useEffect(() => {
        getData().then()
    }, [
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
    ])

    return (
        <div>
            <MaterialReactTable
                initialState={{
                    showColumnFilters: false,
                    density: 'compact',
                }}
                state={{
                    columnFilters,
                    globalFilter,
                    isLoading: componentState === TableComponentStateEnum.LOADING,
                    pagination,
                    showAlertBanner: componentState === TableComponentStateEnum.ERROR,
                    showProgressBars: componentState === TableComponentStateEnum.REFRESHING,
                    sorting,
                    columnPinning,
                    columnSizing,
                    columnVisibility,
                    columnOrder,
                }}
                data={data.page.totalElements > 0 ? data.content : []}
                rowCount={data.page.totalElements}
                columns={columns}
                manualFiltering
                manualPagination
                manualSorting
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                onColumnPinningChange={setColumnPinning}
                onColumnSizingChange={setColumnSizing}
                onColumnVisibilityChange={setColumnVisibility}
                onColumnOrderChange={setColumnOrder}
                enableEditing
                enableColumnOrdering
                enableStickyHeader
                enableStickyFooter
                enableColumnResizing
                enablePinning
                renderRowActions={({row}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '1rem',
                    }}
                    >
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton
                                onClick={async () => {
                                    await setDataEdit(row)
                                    await setCreateModalOpen(true)
                                }}
                            >
                                <Edit/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                <Delete/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={() => {
                    return (
                        <div style={{display: 'inline'}}>
                            <Tooltip arrow title="Remap">
                                <IconButton
                                    onClick={async () => {
                                        await categoryService.remap()
                                        await setSnackbar({
                                            severity: 'success',
                                            message: 'Remap ukončený'
                                        });
                                    }}
                                >
                                    <AddLinkIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip arrow title="Refresh Data">
                                <IconButton onClick={() => getData()}>
                                    <RefreshIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip arrow title="Pridaj data">
                                <IconButton
                                    onClick={async () => {
                                        await setDataEdit(null)
                                        await setCreateModalOpen(true)
                                    }}
                                >
                                    <AddBox></AddBox>
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                }}
                muiTableContainerProps={{sx: {maxHeight: '65vh'}}}
                columnResizeMode="onChange"
                muiPaginationProps={{
                    rowsPerPageOptions: [50, 100, 200],
                }}
                muiToolbarAlertBannerProps={
                    componentState === TableComponentStateEnum.ERROR
                        ? {
                            color: 'error',
                            children: 'Error loading data',
                        }
                        : undefined
                }
            ></MaterialReactTable>
            <CategoryTableModalComponent
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleRow}
                data={dataEdit}
            />
        </div>
    )
}
