import {Box, Stack} from '@mui/material'
import type {
    ColumnFiltersState,
    ColumnOrderState,
    ColumnPinningState,
    ColumnSizingState,
    SortingState,
    VisibilityState,
} from '@tanstack/react-table'
import type {MRT_ColumnDef} from 'material-react-table'
import {MaterialReactTable} from 'material-react-table'
import React, {useEffect, useMemo, useState} from 'react'
import {useRecoilState} from "recoil";
import {userSettingsState} from "../../../atoms/modelAtoms";
import {TableComponentStateEnum} from "../../../enums/TableComponentStateEnum";
import type {ContentWrapperWithPage} from '../../../models/ContentWrapperWithPage'
import type {ReceiptItemModel} from '../../../models/ReceiptItemModel'
import ReceiptItemService from '../../../services/ReceiptItemService'
import UserAppSettingService from '../../../services/UserAppSettingService'
import UtilService from '../../../services/UtilService'

export const ReceiptItemTableComponent: React.FC<{
    refreshTime?: number
    rowSelection: any
}> = ({
          refreshTime,
          rowSelection,
      }) => {
    const [data, setData] = useState<ContentWrapperWithPage<ReceiptItemModel>>(
        UtilService.initContentWrapperWithPage<ReceiptItemModel>(),
    )

    const [userSettings, setUserSettings] = useRecoilState(userSettingsState);
    const [componentState, setComponentState] = useState<TableComponentStateEnum>(TableComponentStateEnum.LOADING)

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [globalFilter, setGlobalFilter] = useState('')
    const [sorting, setSorting] = useState<SortingState>([])
    const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
        left: [],
        right: [],
    })
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
    const [columnSizing, setColumnSizing] = useState<ColumnSizingState>({})
    const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100,
    })

    const receiptItemService: ReceiptItemService = new ReceiptItemService()
    const userAppSettingService: UserAppSettingService
        = new UserAppSettingService()

    const columns = useMemo<MRT_ColumnDef<ReceiptItemModel>[]>(
        () => [
            {
                accessorKey: 'name',
                header: 'Názov',
            },
            {
                accessorKey: 'itemType',
                header: 'Typ',
            },
            {
                accessorKey: 'quantity',
                header: 'Množstvo',
                Footer: () => (
                    <Stack>
                        Celkové množstvo:
                        <Box color="warning.main">
                            {data.content
                                .filter(p => p.itemType !== 'Z')
                                .reduce((acc, curr) => acc + curr.quantity, 0)
                                ?.toLocaleString?.('sk-SK', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2,
                                })}
                        </Box>
                    </Stack>
                ),
            },
            {
                accessorKey: 'unitPrice',
                header: 'Jednotková cena',
                Footer: () => (
                    <Stack>
                        Celková jednotková cena:
                        <Box color="warning.main">
                            {data.content
                                .filter(p => p.itemType !== 'Z')
                                .reduce((acc, curr) => acc + curr.unitPrice, 0)
                                ?.toLocaleString?.('sk-SK', {
                                    style: 'currency',
                                    currency: 'EUR',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2,
                                })}
                        </Box>
                    </Stack>
                ),
            },
            {
                accessorKey: 'discountPrice',
                header: 'Zľava',
                Footer: () => (
                    <Stack>
                        Celková zľava:
                        <Box color="warning.main">
                            {data.content
                                .reduce((acc, curr) => acc + curr.discountPrice, 0)
                                ?.toLocaleString?.('sk-SK', {
                                    style: 'currency',
                                    currency: 'EUR',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2,
                                })}
                        </Box>
                    </Stack>
                ),
            },
            {
                accessorKey: 'price',
                header: 'Celková cena',
                Footer: () => (
                    <Stack>
                        Celková suma:
                        <Box color="warning.main">
                            {data.content
                                .reduce((acc, curr) => acc + curr.price, 0)
                                ?.toLocaleString?.('sk-SK', {
                                    style: 'currency',
                                    currency: 'EUR',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2,
                                })}
                        </Box>
                    </Stack>
                ),
            },
        ],
        [data],
    )

    const getData = async () => {
        if (!data.content.length) {
            setComponentState(TableComponentStateEnum.LOADING);
        } else {
            setComponentState(TableComponentStateEnum.REFRESHING);
        }

        try {
            if (rowSelection != null) {
                const customFilter = new Map<string, string>()
                customFilter.set('receipt_id', rowSelection)

                const _data = await receiptItemService.fetch({
                    page: pagination.pageIndex,
                    size: pagination.pageSize,
                    columnFiltersState: columnFilters,
                    customFilter,
                    sort: sorting.length > 0 ? sorting[0] : undefined,
                })
                setData(_data)
                setComponentState(TableComponentStateEnum.SUCCESS);
            }
        } catch (e) {
            setComponentState(TableComponentStateEnum.ERROR);
            console.error(e)
            return
        }

        if (refreshTime !== null && refreshTime !== undefined && refreshTime > 0) {
            setTimeout(() => getData(), refreshTime)
        }
    }

    useEffect(() => {
        setUserSettings({
            page: 'receiptItemTable',
            columnSizingState: columnSizing,
            columnFiltersState: columnFilters,
            columnPinningState: columnPinning,
            sortingState: sorting,
            visibilityState: columnVisibility,
            columnOrderState: columnOrder,
            globalFilter,
        });
    }, [
        columnSizing,
        columnFilters,
        columnPinning,
        sorting,
        columnVisibility,
        columnOrder,
        globalFilter,
    ])

    useEffect(() => {
        getData().then()
    }, [
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
        rowSelection,
    ])

    useEffect(() => {
        const fetchUserAppSettings = () => {
            userAppSettingService
                .fetch({page: 'receiptItemTable'})
                .then((saveState) => {
                    setColumnPinning(saveState.columnPinningState)
                    setColumnSizing(saveState.columnSizingState)
                    setColumnVisibility(saveState.visibilityState)
                    setColumnFilters(saveState.columnFiltersState)
                    setSorting(saveState.sortingState)
                    setColumnOrder(saveState.columnOrderState)
                    setGlobalFilter(saveState.globalFilter)
                })
        }
        fetchUserAppSettings()
    }, [])

    return (
        <div>
            <MaterialReactTable
                enableStickyHeader
                enableStickyFooter
                muiTableContainerProps={{sx: {maxHeight: '65vh'}}}
                enableColumnResizing
                columnResizeMode="onChange"
                columns={columns}
                data={data?.page?.totalElements > 0 ? data.content : []}
                initialState={{
                    showColumnFilters: false,
                    density: 'compact',
                }}
                manualFiltering
                manualPagination
                manualSorting
                muiToolbarAlertBannerProps={
                    componentState === TableComponentStateEnum.ERROR
                        ? {
                            color: 'error',
                            children: 'Error loading data',
                        }
                        : undefined
                }
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={data?.page?.totalElements}
                enablePinning
                onColumnPinningChange={setColumnPinning}
                onColumnSizingChange={setColumnSizing}
                onColumnVisibilityChange={setColumnVisibility}
                onColumnOrderChange={setColumnOrder}
                enableColumnOrdering
                state={{
                    columnFilters,
                    globalFilter,
                    isLoading: componentState === TableComponentStateEnum.LOADING,
                    pagination,
                    showAlertBanner: componentState === TableComponentStateEnum.ERROR,
                    showProgressBars: componentState === TableComponentStateEnum.REFRESHING,
                    sorting,
                    columnPinning,
                    columnSizing,
                    columnVisibility,
                    columnOrder,
                }}
                defaultColumn={{
                    minSize: 20,
                    maxSize: 900,
                    size: 100,
                }}
                muiPaginationProps={{
                    rowsPerPageOptions: [50, 100, 200],
                }}
            ></MaterialReactTable>
        </div>
    )
}
