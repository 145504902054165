import type { ChartOptions } from 'chart.js'
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import React, { useEffect, useState } from 'react'
import { Pie } from 'react-chartjs-2'
import TransactionService from '../../../services/TransactionService'

export enum CategoryPieChartType {
  DEBIT,
  CREDIT,
}

export const CategoryPieChartComponent: React.FC<{
  loaded: any
  onlyFirstTimeShowLoading?: boolean
  refreshTime?: number
  onChangeData?: any
  loadDataOn: boolean
  dataIn?: any
  categoryPieChartType: CategoryPieChartType
}> = ({
  loaded,
  onlyFirstTimeShowLoading = false,
  refreshTime,
  onChangeData,
  loadDataOn,
  dataIn,
  categoryPieChartType,
}) => {
  ChartJS.register(ArcElement, Tooltip, Legend)

  const [data, setData] = useState<any>({
    labels: [],
    datasets: [
      {
        backgroundColor: [],
        data: [],
      },
    ],
  })

  const options: ChartOptions = {
    // String - A leg,end template
    // Boolean - whether to maintain the starting aspect ratio or not when responsive, if set to false, will take up entire container
    maintainAspectRatio: false,
    // Boolean - whether to make the chart responsive to window resizing
    responsive: true,
  }

  function dynamicColors() {
    const r = Math.floor(Math.random() * 255)
    const g = Math.floor(Math.random() * 255)
    const b = Math.floor(Math.random() * 255)
    return `rgb(${r},${g},${b})`
  }

  useEffect(() => {
    let firstTimeLoaded = true
    const transactionService: TransactionService = new TransactionService()

    async function getData() {
      if (firstTimeLoaded || !onlyFirstTimeShowLoading) {
        loaded(false)
      }

      try {
        let isDebit = false
        let isCredit = false

        if (categoryPieChartType === CategoryPieChartType.DEBIT) {
          isDebit = true
        } else if (categoryPieChartType === CategoryPieChartType.CREDIT) {
          isCredit = true
        }

        const _data = await transactionService.getCategoryNameCreditDebitTotal({
          isDebit,
          isCredit,
        })
        const chartData = JSON.parse(JSON.stringify(data))

        chartData.labels = [...new Set(_data.content.map(p => p.name))]
        chartData.datasets[0].data = []
        await chartData.labels.forEach((x: string) => {
          chartData.datasets[0].data.push(
            _data.content.filter(p => p.name === x)[0].total,
          )
          chartData.datasets[0].backgroundColor.push(dynamicColors())
        })
        setData(chartData)
        onChangeData(chartData)
      } catch (e) {
        console.error(e)
      }

      loaded(true)
      firstTimeLoaded = false

      if (
        refreshTime !== null
                && refreshTime !== undefined
                && refreshTime > 0
      ) {
        setTimeout(() => getData(), refreshTime)
      }
    }

    if (loadDataOn) {
      getData()
    } else {
      if (firstTimeLoaded || !onlyFirstTimeShowLoading) {
        loaded(false)
      }
      setData(dataIn)
      loaded(true)
    }
  }, [dataIn])

  return <Pie data={data} options={options} height={180}/>
}
