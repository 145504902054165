import i18next from 'i18next'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {useRecoilState, useRecoilValue} from "recoil";
import {snackbarState, userSettingsState} from "../atoms/modelAtoms";
import {LanguageEnum} from '../models/LanguageEnum'
import UserAppSettingService from "../services/UserAppSettingService";

export const Header: React.FC = () => {
    const navigate = useNavigate()
    const userSettings = useRecoilValue(userSettingsState);
    const [snackbar, setSnackbar] = useRecoilState(snackbarState);

    const [language, setLanguage] = useState<LanguageEnum>(
        LanguageEnum.EN === (localStorage.getItem('i18nextLng') || 'en')
            ? LanguageEnum.EN
            : LanguageEnum.SK,
    )
    const langToSet = language.includes('sk') ? 'sk' : language.toString()
    if (i18next.language !== langToSet) {
        i18next.changeLanguage(langToSet)
    }

    const {t} = useTranslation()
    const userAppSettingService: UserAppSettingService
        = new UserAppSettingService()

    return (
        <div>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a
                            className="nav-link"
                            data-widget="pushmenu"
                            href="#"
                            role="button"
                        >
                            <i className="fas fa-bars"/>
                        </a>
                    </li>
                </ul>
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <a
                            className="nav-link"
                            data-widget="control-sidebar"
                            data-controlsidebar-slide="true"
                            onClick={() => {
                                if (userSettings) {
                                    userAppSettingService
                                        .save(userSettings)
                                        .then(() => {
                                            setSnackbar({
                                                message: 'Používateľské nastavenia boli uložené.',
                                                severity: 'success',
                                            })
                                        })
                                        .catch((e: any) => console.error(e))
                                }
                            }}
                            role="button"
                        >
                            <i className="fas fa-save"/>
                        </a>
                    </li>
                    <li className="nav-item dropdown">
                        <a
                            className="nav-link"
                            data-toggle="dropdown"
                            href="#"
                            aria-expanded="false"
                        >
                            {language === LanguageEnum.SK ? t('Slovak') : ''}
                            {language === LanguageEnum.EN ? t('English') : ''}
                        </a>
                        <div
                            className="dropdown-menu dropdown-menu-md dropdown-menu-right"
                            style={{
                                left: 'inherit',
                                right: 0,
                            }}
                        >
                            <a
                                onClick={() => {
                                    const language = LanguageEnum.SK
                                    setLanguage(language)
                                    i18next.changeLanguage('sk')
                                }}
                                className="dropdown-item"
                            >
                                {t('Slovak')}
                            </a>
                            <div className="dropdown-divider"/>
                            <a
                                onClick={() => {
                                    const language = LanguageEnum.EN
                                    setLanguage(language)
                                    i18next.changeLanguage('en')
                                }}
                                className="dropdown-item"
                            >
                                {t('English')}
                            </a>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a
                            className="nav-link"
                            data-widget="control-sidebar"
                            data-controlsidebar-slide="true"
                            onClick={() => navigate('/logout')}
                            role="button"
                        >
                            <i className="fas fa-sign-out-alt"/>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    )
}
