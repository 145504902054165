import { defaultApi } from '../constants'
import type { ContentWrapper } from '../models/ContentWrapper'
import type { ContentWrapperWithPage } from '../models/ContentWrapperWithPage'
import type { DatePriceModel } from '../models/DatePriceModel'
import type { FetchParamsModel } from '../models/FetchParamsModel'
import type { ReceiptModel } from '../models/ReceiptModel'
import UtilService from './UtilService'

export default class ReceiptService {
  async fetch(
    fetchParamsModel: FetchParamsModel,
  ): Promise<ContentWrapperWithPage<ReceiptModel>> {
    const { data } = await defaultApi.get(
      'receipts/search/findByContainingFilter',
      {
        params: UtilService.getParams(fetchParamsModel),
      },
    )
    return data
  }

  async getByScan(id: string): Promise<any> {
    const { data } = await defaultApi.post(
            `receipts/getByScan/${id}`
    )
    return data
  }

  async getDatePrice({
    dateFrom,
                           dateTo,
  }: {
    dateFrom?: Date
    dateTo?: Date
  }): Promise<ContentWrapper<DatePriceModel>> {
    const params: {
      dateFrom?: string
      dateTo?: string
    } = {}

    if (dateFrom) {
      params.dateFrom = UtilService.getDateToFilter(dateFrom)
    }
    if (dateTo) {
      params.dateTo = UtilService.getDateToFilter(dateTo)
    }

    const { data } = await defaultApi.get('receipts/search/getDatePrice', {
      params,
    })
    return data
  }

  async getTopOrgNamePrice({
    dateFrom,
                                 dateTo,
                                 numberOfTop,
  }: {
    dateFrom?: Date
    dateTo?: Date
    numberOfTop?: number
  }): Promise<ContentWrapper<DatePriceModel>> {
    const params: {
      dateFrom?: string
      dateTo?: string
      size?: number
    } = {}

    if (dateFrom) {
      params.dateFrom = UtilService.getDateToFilter(dateFrom)
    }
    if (dateTo) {
      params.dateTo = UtilService.getDateToFilter(dateTo)
    }
    if (numberOfTop) {
      params.size = numberOfTop
    }

    const { data } = await defaultApi.get(
      'receipts/search/getTopOrgNamePrice',
      {
        params,
      },
    )
    return data
  }
}
