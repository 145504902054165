import type { RowSelectionState } from '@tanstack/react-table'
import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Box } from '../../components/Box'
import { ReceiptItemTableComponent } from './components/ReceiptItemTableComponent'
import { ReceiptTableComponent } from './components/ReceiptTableComponent'

export const Receipt: React.FC = () => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  return (
        <div className="content-wrapper">
            <section className="content">
                <div className="container-fluid">
                    <Row>
                        <Col md={5}>
                            <Box loaded={true} title={<div>Bločky</div>}>
                                <div className="box-overflow" style={{ minHeight: '200px' }}>
                                    <ReceiptTableComponent
                                        onRowSelectionChange={setRowSelection}
                                    ></ReceiptTableComponent>
                                </div>
                            </Box>
                        </Col>
                        <Col md={7}>
                            <Box loaded={true} title={<div>Riadky bločkov</div>}>
                                <div className="box-overflow" style={{ minHeight: '200px' }}>
                                    <ReceiptItemTableComponent
                                        rowSelection={rowSelection}
                                    ></ReceiptItemTableComponent>
                                </div>
                            </Box>
                        </Col>
                    </Row>
                </div>
            </section>
        </div>
  )
}

export default Receipt
