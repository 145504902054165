import {IconButton} from "@mui/material";
import React, {useState} from 'react'

import {Col, Row} from 'react-bootstrap'
import {FaExpand} from 'react-icons/fa'
import {Box} from '../../components/Box'
import {ModalCard} from '../../components/ModalCard'
import {AccountValueComponent} from './components/AccountValueComponent'
import {CategoryPieChartComponent, CategoryPieChartType} from './components/CategoryPieChartComponent'
import {CreditDebitChartComponent} from './components/CreditDebitChartComponent'
import {TopItemNameCountChartComponent} from './components/TopItemNameCountChartComponent'
import {TopItemNamePriceChartComponent} from './components/TopItemNamePriceChartComponent'
import {TopItemNameQuantityChartComponent} from './components/TopItemNameQuantityChartComponent'

export const Dashboard: React.FC = () => {
    const [categoryPieChartDebitOpen, setCategoryPieChartDebitOpen]
        = useState<boolean>(false)
    const [categoryPieChartDebitLoaded, setCategoryPieChartDebitLoaded]
        = useState<boolean>(false)
    const [categoryPieChartDebitData, setCategoryPieChartDebitData]
        = useState<any>({
        labels: [],
        datasets: [
            {
                backgroundColor: [],
                data: [],
            },
        ],
    })

    const [categoryPieChartCreditOpen, setCategoryPieChartCreditOpen]
        = useState<boolean>(false)
    const [categoryPieChartCreditLoaded, setCategoryPieChartCreditLoaded]
        = useState<boolean>(false)
    const [categoryPieChartCreditData, setCategoryPieChartCreditData]
        = useState<any>({
        labels: [],
        datasets: [
            {
                backgroundColor: [],
                data: [],
            },
        ],
    })

    const [creditDebitChartOpen, setCreditDebitChartOpen]
        = useState<boolean>(false)
    const [creditDebitChartLoaded, setCreditDebitChartLoaded]
        = useState<boolean>(false)
    const [creditDebitChartData, setCreditDebitChartData] = useState<any>([])

    const [topItemNamePriceChartOpen, setTopItemNamePriceChartOpen]
        = useState<boolean>(false)
    const [topItemNamePriceChartLoaded, setTopItemNamePriceChartLoaded]
        = useState<boolean>(false)
    const [topItemNamePriceChartData, setTopItemNamePriceChartData]
        = useState<any>([])

    const [topItemNameQuantityChartOpen, setTopItemNameQuantityChartOpen]
        = useState<boolean>(false)
    const [topItemNameQuantityChartLoaded, setTopItemNameQuantityChartLoaded]
        = useState<boolean>(false)
    const [topItemNameQuantityChartData, setTopItemNameQuantityChartData]
        = useState<any>([])

    const [topItemNameCountChartOpen, setTopItemNameCountChartOpen]
        = useState<boolean>(false)
    const [topItemNameCountChartLoaded, setTopItemNameCountChartLoaded]
        = useState<boolean>(false)
    const [topItemNameCountChartData, setTopItemNameCountChartData]
        = useState<any>([])

    return (
        <div className="content-wrapper">
            <section className="content">
                <div className="container-fluid">
                    <Row>
                        <AccountValueComponent onlyFirstTimeShowLoading={false}/>
                        <Col md={6}>
                            <ModalCard
                                style={{minHeight: '65vh'}}
                                title={'Suma po kategóriach - CREDIT'}
                                open={categoryPieChartCreditOpen}
                                onClose={() => {
                                    setCategoryPieChartCreditOpen(false)
                                }}
                            >
                                <CategoryPieChartComponent
                                    loaded={() => {
                                    }}
                                    loadDataOn={false}
                                    dataIn={categoryPieChartCreditData}
                                    categoryPieChartType={CategoryPieChartType.CREDIT}
                                />
                            </ModalCard>
                            <Box
                                loaded={categoryPieChartCreditLoaded}
                                title={
                                    <div>
                                        <i className="fas fa-chart-pie mr-1"/>
                                        Suma po kategóriach - CREDIT
                                    </div>
                                }
                                actions={
                                    <IconButton onClick={() => setCategoryPieChartCreditOpen(true)}>
                                        <FaExpand/>
                                    </IconButton>
                                }
                            >
                                <div className="box-overflow" style={{minHeight: '400px'}}>
                                    <CategoryPieChartComponent
                                        onlyFirstTimeShowLoading={true}
                                        loaded={(state: boolean) => {
                                            setCategoryPieChartCreditLoaded(state)
                                        }}
                                        loadDataOn={true}
                                        onChangeData={(data: any) => {
                                            setCategoryPieChartCreditData(data)
                                        }}
                                        categoryPieChartType={CategoryPieChartType.CREDIT}
                                    />
                                </div>
                            </Box>
                        </Col>

                        <Col md={6}>
                            <ModalCard
                                style={{minHeight: '400px'}}
                                title={'Suma po kategóriach - DEBIT'}
                                open={categoryPieChartDebitOpen}
                                onClose={() => {
                                    setCategoryPieChartDebitOpen(false)
                                }}
                            >
                                <CategoryPieChartComponent
                                    loaded={() => {
                                    }}
                                    loadDataOn={false}
                                    dataIn={categoryPieChartDebitData}
                                    categoryPieChartType={CategoryPieChartType.CREDIT}
                                />
                            </ModalCard>
                            <Box
                                loaded={categoryPieChartDebitLoaded}
                                title={
                                    <div>
                                        <i className="fas fa-chart-pie mr-1"/>
                                        Suma po kategóriach - DEBIT
                                    </div>
                                }
                                actions={
                                    <IconButton onClick={() => setCategoryPieChartDebitOpen(true)}>
                                        <FaExpand/>
                                    </IconButton>
                                }
                            >
                                <div className="box-overflow" style={{minHeight: '400px'}}>
                                    <CategoryPieChartComponent
                                        onlyFirstTimeShowLoading={true}
                                        loaded={(state: boolean) => {
                                            setCategoryPieChartDebitLoaded(state)
                                        }}
                                        loadDataOn={true}
                                        onChangeData={(data: any) => {
                                            setCategoryPieChartDebitData(data)
                                        }}
                                        categoryPieChartType={CategoryPieChartType.DEBIT}
                                    />
                                </div>
                            </Box>
                        </Col>
                        <Col md={12}>
                            <ModalCard
                                style={{minHeight: '400px'}}
                                title={'Credit,debit po mesiacoch'}
                                open={creditDebitChartOpen}
                                onClose={() => setCreditDebitChartOpen(false)}
                            >
                                <CreditDebitChartComponent
                                    loaded={() => {
                                    }}
                                    loadDataOn={false}
                                    dataIn={creditDebitChartData}
                                />
                            </ModalCard>
                            <Box
                                loaded={creditDebitChartLoaded}
                                title={<div>Credit, debit po mesiacoch</div>}
                                actions={
                                    <IconButton onClick={() => setCreditDebitChartOpen(true)}>
                                        <FaExpand/>
                                    </IconButton>
                                }
                            >
                                <div className="box-overflow">
                                    <CreditDebitChartComponent
                                        onlyFirstTimeShowLoading={true}
                                        loaded={(state: boolean) => {
                                            setCreditDebitChartLoaded(state)
                                        }}
                                        loadDataOn={true}
                                        onChangeData={(data: any) => {
                                            setCreditDebitChartData(data)
                                        }}
                                    />
                                </div>
                            </Box>
                        </Col>
                        <Col md={6}>
                            <ModalCard
                                style={{minHeight: '400px'}}
                                title={'Top položky podľa ceny'}
                                open={topItemNamePriceChartOpen}
                                onClose={() => setTopItemNamePriceChartOpen(false)}
                            >
                                <TopItemNamePriceChartComponent
                                    loaded={() => {
                                    }}
                                    loadDataOn={false}
                                    dataIn={topItemNamePriceChartData}
                                />
                            </ModalCard>
                            <Box
                                loaded={topItemNamePriceChartLoaded}
                                title={<div>Top položky podľa ceny</div>}
                                actions={
                                    <IconButton onClick={() => setTopItemNamePriceChartOpen(true)}>
                                        <FaExpand/>
                                    </IconButton>
                                }
                            >
                                <div className="box-overflow">
                                    <TopItemNamePriceChartComponent
                                        onlyFirstTimeShowLoading={true}
                                        loaded={(state: boolean) => {
                                            setTopItemNamePriceChartLoaded(state)
                                        }}
                                        loadDataOn={true}
                                        onChangeData={(data: any) => {
                                            setTopItemNamePriceChartData(data)
                                        }}
                                    />
                                </div>
                            </Box>
                        </Col>
                        <Col md={6}>
                            <ModalCard
                                style={{minHeight: '400px'}}
                                title={'Top položky podľa množstva'}
                                open={topItemNameQuantityChartOpen}
                                onClose={() => setTopItemNameQuantityChartOpen(false)}
                            >
                                <TopItemNameQuantityChartComponent
                                    loaded={() => {
                                    }}
                                    loadDataOn={false}
                                    dataIn={topItemNameQuantityChartData}
                                />
                            </ModalCard>
                            <Box
                                loaded={topItemNameQuantityChartLoaded}
                                title={<div>Top položky podľa množstva</div>}
                                actions={
                                    <IconButton onClick={() => setTopItemNameQuantityChartOpen(true)}>
                                        <FaExpand/>
                                    </IconButton>
                                }
                            >
                                <div className="box-overflow">
                                    <TopItemNameQuantityChartComponent
                                        onlyFirstTimeShowLoading={true}
                                        loaded={(state: boolean) => {
                                            setTopItemNameQuantityChartLoaded(state)
                                        }}
                                        loadDataOn={true}
                                        onChangeData={(data: any) => {
                                            setTopItemNameQuantityChartData(data)
                                        }}
                                    />
                                </div>
                            </Box>
                        </Col>
                        <Col md={6}>
                            <ModalCard
                                style={{minHeight: '400px'}}
                                title={'Top položky najčastejšie kupované'}
                                open={topItemNameCountChartOpen}
                                onClose={() => setTopItemNameCountChartOpen(false)}
                            >
                                <TopItemNameCountChartComponent
                                    loaded={() => {}}
                                    loadDataOn={false}
                                    dataIn={topItemNameCountChartData}
                                />
                            </ModalCard>
                            <Box
                                loaded={topItemNameCountChartLoaded}
                                title={<div>Top položky najčastejšie kupované</div>}
                                actions={
                                    <IconButton onClick={() => setTopItemNameCountChartOpen(true)}>
                                        <FaExpand/>
                                    </IconButton>
                                }
                            >
                                <div className="box-overflow">
                                    <TopItemNameCountChartComponent
                                        onlyFirstTimeShowLoading={true}
                                        loaded={(state: boolean) => {
                                            setTopItemNameCountChartLoaded(state)
                                        }}
                                        loadDataOn={true}
                                        onChangeData={(data: any) => {
                                            setTopItemNameCountChartData(data)
                                        }}
                                    />
                                </div>
                            </Box>
                        </Col>

                    </Row>
                </div>
            </section>
        </div>
    )
}

export default Dashboard
