import {defaultApi} from '../constants'
import type {BalanceModel} from '../models/BalanceModel'
import type {CategoryNameCreditDebitTotalModel} from '../models/CategoryNameCreditDebitTotalModel'
import type {ContentWrapper} from '../models/ContentWrapper'
import type {ContentWrapperWithPage} from '../models/ContentWrapperWithPage'
import type {DateCreditDebitTotalModel} from '../models/DateCreditDebitTotalModel'
import type {FetchParamsModel} from '../models/FetchParamsModel'
import type {TransactionModel} from '../models/TransactionModel'
import UtilService from './UtilService'

export default class TransactionService {
    async fetch(
        fetchParamsModel: FetchParamsModel,
    ): Promise<ContentWrapperWithPage<TransactionModel>> {
        const {data} = await defaultApi.get(
            'transactions/search/findByContainingFilter',
            {
                params: UtilService.getParams(fetchParamsModel),
            },
        )
        return data
    }

    async getBalance(date: Date): Promise<ContentWrapper<BalanceModel>> {
        const {data} = await defaultApi.get('transactions/search/getBalance', {
            params: {
                datum: UtilService.getDateToFilter(date),
            },
        })
        return data
    }

    async getCategoryNameCreditDebitTotal({
                                              dateFrom,
                                              dateTo,
                                              isCredit,
                                              isDebit,
                                          }: {
        dateFrom?: Date
        dateTo?: Date
        isCredit?: boolean
        isDebit?: boolean
    }): Promise<ContentWrapperWithPage<CategoryNameCreditDebitTotalModel>> {
        const params: {
            dateFrom?: string
            dateTo?: string
            isCredit?: boolean
            isDebit?: boolean
        } = {}

        if (dateFrom) {
            params.dateFrom = UtilService.getDateToFilter(dateFrom)
        }
        if (dateTo) {
            params.dateTo = UtilService.getDateToFilter(dateTo)
        }
        if (isCredit) {
            params.isCredit = isCredit
        }
        if (isDebit) {
            params.isDebit = isDebit
        }

        const {data} = await defaultApi.get(
            'transactions/search/getCategoryNameCreditDebitTotal',
            {
                params,
            },
        )
        return data
    }

    async getDayDateCreditDebitTotal({
                                         dateFrom,
                                         dateTo,
                                     }: {
        dateFrom?: Date
        dateTo?: Date
    }): Promise<ContentWrapperWithPage<DateCreditDebitTotalModel>> {
        const params: {
            dateFrom?: string
            dateTo?: string
        } = {}

        if (dateFrom) {
            params.dateFrom = UtilService.getDateToFilter(dateFrom)
        }
        if (dateTo) {
            params.dateTo = UtilService.getDateToFilter(dateTo)
        }

        const {data} = await defaultApi.get(
            'transactions/search/getDayDateCreditDebitTotal',
            {
                params,
            },
        )
        return data
    }

    async getMonthDateCreditDebitTotal({
                                           dateFrom,
                                           dateTo,
                                       }: {
        dateFrom?: Date
        dateTo?: Date
    }): Promise<ContentWrapperWithPage<DateCreditDebitTotalModel>> {
        const params: {
            dateFrom?: string
            dateTo?: string
        } = {}

        if (dateFrom) {
            params.dateFrom = UtilService.getDateToFilter(dateFrom)
        }
        if (dateTo) {
            params.dateTo = UtilService.getDateToFilter(dateTo)
        }

        const {data} = await defaultApi.get(
            'transactions/search/getMonthDateCreditDebitTotal',
            {
                params,
            },
        )
        return data
    }

    async getYearDateCreditDebitTotal({
                                          dateFrom,
                                          dateTo,
                                      }: {
        dateFrom?: Date
        dateTo?: Date
    }): Promise<ContentWrapperWithPage<DateCreditDebitTotalModel>> {
        const params: {
            dateFrom?: string
            dateTo?: string
        } = {}

        if (dateFrom) {
            params.dateFrom = UtilService.getDateToFilter(dateFrom)
        }
        if (dateTo) {
            params.dateTo = UtilService.getDateToFilter(dateTo)
        }

        const {data} = await defaultApi.get(
            'transactions/search/getYearDateCreditDebitTotal',
            {
                params,
            },
        )
        return data
    }
}
