import { defaultApi } from '../constants'
import type { ConditionalKeyModel } from '../models/ConditionalKeyModel'

export default class ConditionKeywordsService {
  async fetch({ name }: { name: string }): Promise<ConditionalKeyModel[]> {
    const params: { name: string } = {
      name,
    }

    const { data } = await defaultApi.get('conditionKeywords', {
      params,
    })
    return data
  }
}
