import React from 'react'
import {createRoot} from "react-dom/client";
import {BrowserRouter} from 'react-router-dom'
import {RecoilRoot} from "recoil";
import {App} from './App'
import './i18nextConf'
import './index.css'

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<BrowserRouter>
    <RecoilRoot>
        <App/>
    </RecoilRoot>
</BrowserRouter>);
