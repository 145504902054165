import {atom} from 'recoil'
import {SnackbarInput} from "../models/SnackbarInput";
import {UserSettingsInput} from "../models/UserSettingsInput";

export const userSettingsState = atom<UserSettingsInput | null>({
    key: 'userSettingsState',
    default: null,
})

export const snackbarState = atom<SnackbarInput | null>({
    key: 'snackbarState',
    default: null,
})
