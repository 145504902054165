import type { ContentWrapperWithPage } from '../models/ContentWrapperWithPage'
import type { FetchParamsModel } from '../models/FetchParamsModel'

export default class UtilService {
  static getDateToFilter(date: Date): string {
    return (
            `${date.getFullYear()
            }-${
                (date.getMonth() + 1).toString().length === 1 ? '0' : ''
            }${date.getMonth() + 1
            }-${
                date.getDate().toString().length === 1 ? '0' : ''
            }${date.getDate()}`
    )
  }

  static datasetKeyProvider() {
    return (Math.random() + 1).toString(36).substring(7)
  }

  static getParams(fetchParamsModel: FetchParamsModel) {
    const params: {
      page: number
      size: number
      projection?: string
      sort?: string
      global?: string
    } = {
      page: fetchParamsModel.page,
      size: fetchParamsModel.size,
    }

    if (fetchParamsModel.projection) {
      params.projection = fetchParamsModel.projection
    }

    if (fetchParamsModel.columnFiltersState) {
      fetchParamsModel.columnFiltersState.forEach((p) => {
        (params as any)[p.id.replaceAll('.', '_')] = p.value
      })
    }

    if (fetchParamsModel.customFilter) {
      fetchParamsModel.customFilter.forEach((value, key) => {
        (params as any)[key.replaceAll('.', '_')] = value
      })
    }

    if (fetchParamsModel.globalFilter) {
      params.global = fetchParamsModel.globalFilter
    }

    if (fetchParamsModel.sort) {
      params.sort
                = `${fetchParamsModel.sort.id
            },${
                fetchParamsModel.sort.desc ? 'desc' : 'asc'}`
    }

    return params
  }

  static initContentWrapperWithPage<T>(): ContentWrapperWithPage<T> {
    return {
      page: {
        totalElements: 0,
        number: 0,
        totalPages: 0,
        size: 0,
      },
      content: [],
    }
  }
}
