import { Button, Grid, TextField } from '@mui/material'
import React, { useState } from 'react'
import ReceiptService from '../../../services/ReceiptService'

export const ManualCodeComponent: React.FC<{ onReadCompleted?: any }> = ({
  onReadCompleted,
}) => {
  const receiptService: ReceiptService = new ReceiptService()

  const [uid, setUID] = useState<string>('')

  async function handleUID(uid: string) {
    if (uid != null) {
      try {
        await receiptService.getByScan(uid)
        onReadCompleted()
      } catch (e) {
        console.error(e)
      }
    }
  }

  return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField style={{ width: '100%' }} id="standard-basic" label="UID" variant="standard"
                           onChange={(x) => {
                             setUID(x.target.value)
                           }}
                />
            </Grid>
            <Grid item xs={4} style={{ marginRight: '0px' }}>
                <Button variant="contained" color="success" onClick={async () => await handleUID(uid)}>
                    Získaj dáta pre bloček
                </Button>
            </Grid>
        </Grid>
  )
}
