import QueryStatsIcon from '@mui/icons-material/QueryStats'
import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { InfoBox } from '../../../components/InfoBox'
import type { BalanceModel } from '../../../models/BalanceModel'
import TransactionService from '../../../services/TransactionService'

export const AccountValueComponent: React.FC<{
  onlyFirstTimeShowLoading: boolean
  refreshTime?: number
}> = ({
  refreshTime,
  onlyFirstTimeShowLoading,
}) => {
  const [data, setData] = useState<BalanceModel[]>([])
  const [dataSum, setDataSum] = useState<BalanceModel | null>(null)
  const [loaded, setLoaded] = useState<boolean>(false)

  useEffect(() => {
    let firstTimeLoaded = true
    const transactionService: TransactionService = new TransactionService()

    async function getData() {
      if (firstTimeLoaded || !onlyFirstTimeShowLoading) {
        setLoaded(false)
      }

      try {
        const _data = await transactionService.getBalance(new Date())
        const _sum = _data.content
          .map(m => m.total)
          .reduce((acc, a) => acc + a, 0)
        const _credit = _data.content
          .map(m => m.credit)
          .reduce((acc, a) => acc + a, 0)
        const _debit = _data.content
          .map(m => m.debit)
          .reduce((acc, a) => acc + a, 0)
        const _sumModel = {
          customName: 'Spolu',
          iban: 'Spolu',
          total: _sum,
          credit: _credit,
          debit: _debit,
        } as BalanceModel
        setDataSum(_sumModel)

        setData(_data.content)
      } catch (e) {
        console.error(e)
      }

      setLoaded(true)
      firstTimeLoaded = false

      if (
        refreshTime !== null
                && refreshTime !== undefined
                && refreshTime > 0
      ) {
        setTimeout(() => getData(), refreshTime)
      }
    }

    getData()
  }, [])

  return (
        <>
            {data
              .filter(p => p.total > 1)
              .map((dat: BalanceModel) => (
                    <Col
                        key={
                            dat.customName == null || dat.customName === ''
                              ? dat.iban
                              : dat.customName
                        }
                        md={6}
                    >
                        <InfoBox
                            title={
                                dat.customName == null || dat.customName === ''
                                  ? dat.iban
                                  : dat.customName
                            }
                            value={dat.total.toFixed(2).toString()}
                            icon={<QueryStatsIcon fontSize="large"></QueryStatsIcon>}
                            style={{
                              backgroundColor: 'rgba(250, 65, 65, 0.8)',
                              color: 'white',
                            }}
                            loaded={loaded}
                        ></InfoBox>
                    </Col>
              ))}
            {dataSum
              ? (
                    <Col md={12}>
                        <InfoBox
                            title={dataSum.customName}
                            value={dataSum.total.toFixed(2).toString()}
                            icon={<QueryStatsIcon fontSize="large"></QueryStatsIcon>}
                            style={{
                              backgroundColor: 'rgba(250, 65, 65, 0.8)',
                              color: 'white',
                            }}
                            loaded={loaded}
                        ></InfoBox>
                    </Col>
                )
              : (
                    <></>
                )}
        </>
  )
}
