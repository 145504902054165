import {SilentRequest} from "@azure/msal-browser";
import {msalInstance} from "../App";
import {loginRequest} from "../authConfig";

export async function getToken(): Promise<string | null> {
    const currentAccount = msalInstance.getActiveAccount();
    const accessTokenRequest = {
        scopes: loginRequest.scopes,
        account: currentAccount,
    } as SilentRequest;

    if (currentAccount) {
        const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
        return `${accessTokenResponse.accessToken}`;
    }
    return null
}

export function getPreferredUsername() {
    return msalInstance.getActiveAccount()?.name
}
