import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import type { AccountModel } from '../../../models/AccountModel'

export const AccountTableModalComponent: FC<{
  data?: any
  columns: any[]
  onClose: () => void
  onSubmit: (values: AccountModel) => void
  open: boolean
}> = ({
  data,
  open,
  columns,
  onClose,
  onSubmit,
}) => {
  const [values, setValues] = useState<any>(() =>
    columns
      .filter(p => p.accessorKey === 'customName' || p.accessorKey === 'id')
      .reduce((acc, column) => {
        acc[column.accessorKey ?? '']
                    = data != null ? data.original[column.accessorKey] : ''
        return acc
      }, {} as any),
  )

  useEffect(() => {
    setValues(
      columns.reduce((acc, column) => {
        acc[column.accessorKey ?? '']
                    = data != null ? data.original[column.accessorKey] : ''
        return acc
      }, {} as any),
    )
  }, [data])

  const handleSubmit = () => {
    // put your validation logic here
    onSubmit(values)
    onClose()
  }

  return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">{'Upraviť účet'}</DialogTitle>
            <DialogContent>
                <form onSubmit={e => e.preventDefault()}>
                    <Stack
                        sx={{
                          width: '100%',
                          minWidth: {
                            xs: '300px',
                            sm: '360px',
                            md: '400px',
                          },
                          gap: '1.5rem',
                          marginTop: '10px',
                        }}
                    >
                        {columns
                          .filter(p => p.accessorKey === 'customName')
                          .map(column => (
                                <TextField
                                    multiline={true}
                                    minRows={1}
                                    key={column.accessorKey}
                                    label={column.header}
                                    name={column.accessorKey}
                                    value={values[column.accessorKey]}
                                    onChange={e =>
                                      setValues({
                                        ...values,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                />
                          ))}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Zrušiť</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    Uložiť
                </Button>
            </DialogActions>
        </Dialog>
  )
}
