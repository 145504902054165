import React, { useEffect, useState } from 'react'
import { Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import ReceiptItemService from '../../../services/ReceiptItemService'

export const TopItemNamePriceChartComponent: React.FC<{
  loaded: any
  onlyFirstTimeShowLoading?: boolean
  refreshTime?: number
  onChangeData?: any
  loadDataOn: boolean
  dataIn?: any
}> = ({
  loaded,
  onlyFirstTimeShowLoading = false,
  refreshTime,
  onChangeData,
  loadDataOn,
  dataIn,
}) => {
  let timeout: any

  const [data, setData] = useState<any>([])

  useEffect(() => {
    let firstTimeLoaded = true
    const receiptItemService: ReceiptItemService = new ReceiptItemService()

    async function getData() {
      if (firstTimeLoaded || !onlyFirstTimeShowLoading) {
        loaded(false)
      }

      try {
        const _data = await receiptItemService.getTopItemNamePrice({
          numberOfTop: 10,
        })
        setData(_data.content)
        onChangeData(_data.content)
      } catch (e) {
        console.error(e)
      }

      loaded(true)
      firstTimeLoaded = false

      if (
        refreshTime !== null
                && refreshTime !== undefined
                && refreshTime > 0
      ) {
        timeout = setTimeout(() => getData(), refreshTime)
      }
    }

    if (loadDataOn) {
      getData()
    } else {
      if (firstTimeLoaded || !onlyFirstTimeShowLoading) {
        loaded(false)
      }
      setData(dataIn)
      loaded(true)
    }
  }, [dataIn])

  useEffect(() => {
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const renderCustomizedLabel = (data: any) => {
    const {
      x,
      y,
      width,
      height,
      value,
    } = data as {
      x: number
      y: number
      width: number
      height: number
      value: number
    }

    const fireOffset = value.toString().length < 5
    const offset = fireOffset ? -40 : 5
    return (
            <text
                x={x + width - offset}
                y={y + height - 5}
                fill={fireOffset ? '#285A64' : '#fff'}
                textAnchor="end"
            >
                {value}
            </text>
    )
  }

  return (
        <ResponsiveContainer aspect={1}>
            <BarChart
                data={data}
                margin={{
                  top: 5,
                  right: 5,
                  left: 5,
                  bottom: 5,
                }}
                layout="vertical"
            >
                <Tooltip/>
                <XAxis type="number" stroke="#285A64" hide={true}/>
                <YAxis
                    type="category"
                    dataKey="price"
                    axisLine={false}
                    tickLine={false}
                    style={{ fill: '#285A64' }}
                    hide={true}
                />
                <Bar dataKey="price" fill="#285A64" barSize={20}>
                    <LabelList
                        dataKey="name"
                        content={renderCustomizedLabel}
                        position="insideRight"
                        style={{ fill: 'white' }}
                    />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
  )
}
