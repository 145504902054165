import { defaultApi } from '../constants'
import type { AccountModel } from '../models/AccountModel'
import type { ContentWrapperWithPage } from '../models/ContentWrapperWithPage'
import type { FetchParamsModel } from '../models/FetchParamsModel'
import UtilService from './UtilService'

export default class AccountService {
  async fetch(
    fetchParamsModel: FetchParamsModel,
  ): Promise<ContentWrapperWithPage<AccountModel>> {
    const { data } = await defaultApi.get(
      'accounts/search/findByContainingFilter',
      {
        params: UtilService.getParams(fetchParamsModel),
      },
    )
    return data
  }

  async update(account: AccountModel) {
    const { data } = await defaultApi.put(`accounts/${account.id}`, account)
    return data
  }
}
