import React from 'react'

export const InfoBox: React.FC<{
  title: string | React.ReactElement | React.ReactElement[]
  value: string
  loaded?: boolean
  icon: React.ReactElement
  style: React.CSSProperties
}> = ({ title, loaded = true, value, icon, style }) => {
  return (
    <div className="info-box">
      <span className="info-box-icon" style={style}>
        {icon}
      </span>
      <div className="info-box-content">
        <span className="info-box-text">{title}</span>
        <span className="info-box-number">{value}</span>
      </div>
      {!loaded
        ? (
        <div className="overlay">
          <div className="fa-1x">
            <i className="fas fa-spinner fa-pulse fa-spin fa-3x" />
          </div>
        </div>
          )
        : (
            ''
          )}
    </div>
  )
}
