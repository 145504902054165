import {Alert, Snackbar} from "@mui/material";
import React from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import './App.css'
import {useRecoilState} from "recoil";
import {snackbarState} from "./atoms/modelAtoms";
import {AdminLTE} from './components/AdminLTE'
import Account from './pages/account/Account'
import Category from './pages/category/Category'
import Dashboard from './pages/dashboard/Dashboard'
import Logout from './pages/logout/Logout'
import Receipt from './pages/receipt/Receipt'
import RepeatableActionTemplate from './pages/repeatableActionTemplate/RepeatableActionTemplate'
import Transakcie from './pages/transakcie/Transakcie'
import {AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsalAuthentication} from "@azure/msal-react";
import {AuthenticationResult, EventMessage, EventType, InteractionType, PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

export default function PrivateRoute({children}: { children: React.ReactElement }) {
    useMsalAuthentication(InteractionType.Redirect);
    return <>
        <AuthenticatedTemplate>
            {children}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <p>You are not signed in! Please sign in.</p>
        </UnauthenticatedTemplate>
    </>
}

export const App: React.FC = () => {
    const [snackbar, setSnackbar] = useRecoilState(snackbarState);

    return (
        <div>
            <Snackbar
                open={snackbar != null}
                autoHideDuration={6000}
                onClose={() => snackbar == null}
            >
                <Alert
                    onClose={() => snackbar == null}
                    severity={snackbar?.severity}
                    sx={{width: '100%'}}
                >
                    {snackbar?.message}
                </Alert>
            </Snackbar>
            <MsalProvider instance={msalInstance}>
                <Routes>
                    <Route
                        path="/logout"
                        element={
                            <PrivateRoute>
                                <Logout/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/dashboard"
                        element={
                            <PrivateRoute>
                                <AdminLTE>
                                    <Dashboard></Dashboard>
                                </AdminLTE>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/transakcie"
                        element={
                            <PrivateRoute>
                                <AdminLTE>
                                    <Transakcie></Transakcie>
                                </AdminLTE>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/kategoria"
                        element={
                            <PrivateRoute>
                                <AdminLTE>
                                    <Category></Category>
                                </AdminLTE>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="/templateAkcia"
                        element={
                            <PrivateRoute>
                                <AdminLTE>
                                    <RepeatableActionTemplate></RepeatableActionTemplate>
                                </AdminLTE>
                            </PrivateRoute>
                        }
                    ></Route>

                    <Route
                        path="/ucet"
                        element={
                            <PrivateRoute>
                                <AdminLTE>
                                    <Account></Account>
                                </AdminLTE>
                            </PrivateRoute>
                        }
                    ></Route>

                    <Route
                        path="/blok"
                        element={
                            <PrivateRoute>
                                <AdminLTE>
                                    <Receipt></Receipt>
                                </AdminLTE>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="*"
                        element={
                            <PrivateRoute>
                                <Navigate to="/dashboard"/>
                            </PrivateRoute>
                        }
                    ></Route>
                </Routes>
            </MsalProvider>
        </div>
    )
}
