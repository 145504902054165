import {
    Card,
    CardContent,
    CardHeader,
    IconButton,
    Modal, Skeleton
} from '@mui/material'
import React from 'react'

export const ModalCard: React.FC<{
    title: string | React.ReactElement | React.ReactElement[]
    children: React.ReactElement
    style?: React.CSSProperties
    onClose?: any
    open: boolean
}> = ({
          title,
          children,
          style,
          onClose,
          open
      }) => {

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Card style={{
                minWidth: '65vh',
                minHeight: '65vh',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                ...style
            }}>
                <CardHeader
                    action={
                        <IconButton onClick={onClose}>
                            <i className="fas fa-times mr-1"/>
                        </IconButton>
                    }
                    title={title}
                />
                <CardContent style={{
                    minWidth: '65vh',
                    minHeight: '65vh',
                }}>
                    {children}
                </CardContent>
            </Card>
        </Modal>
    )
}
