import {Box, Stack} from '@mui/material'
import type {
    ColumnFiltersState,
    ColumnOrderState,
    ColumnPinningState,
    ColumnSizingState,
    SortingState,
    VisibilityState,
} from '@tanstack/react-table'
import type {MRT_ColumnDef} from 'material-react-table'
import {MaterialReactTable} from 'material-react-table'
import React, {useEffect, useMemo, useState} from 'react'
import {useRecoilState} from "recoil";
import {userSettingsState} from "../../../atoms/modelAtoms";
import {TableComponentStateEnum} from "../../../enums/TableComponentStateEnum";
import type {ContentWrapperWithPage} from '../../../models/ContentWrapperWithPage'
import type {TransactionModel} from '../../../models/TransactionModel'
import TransactionService from '../../../services/TransactionService'
import UserAppSettingService from '../../../services/UserAppSettingService'

export const TransactionTableComponent: React.FC<{
    refreshTime?: number
}> = ({refreshTime}) => {
    let timeout: any

    const transactionService: TransactionService = new TransactionService()
    const userAppSettingService: UserAppSettingService
        = new UserAppSettingService()

    const [data, setData] = useState<ContentWrapperWithPage<TransactionModel>>({
        page: {
            totalElements: 0,
            number: 0,
            totalPages: 0,
            size: 0,
        },
        content: [],
    })

    const [userSettings, setUserSettings] = useRecoilState(userSettingsState);
    const [componentState, setComponentState] = useState<TableComponentStateEnum>(TableComponentStateEnum.LOADING)

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [globalFilter, setGlobalFilter] = useState('')
    const [sorting, setSorting] = useState<SortingState>([])
    const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
        left: [],
        right: [],
    })
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
    const [columnSizing, setColumnSizing] = useState<ColumnSizingState>({})
    const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100,
    })

    const columns = useMemo<MRT_ColumnDef<TransactionModel>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'Id',
                accessorFn: originalRow => originalRow?.id || ''
            },
            {
                accessorKey: 'bookDate',
                header: 'Dátum zaúčtovania',
                accessorFn: originalRow => originalRow?.bookDate || ''
            },
            {
                accessorKey: 'valueDate',
                header: 'Dátum',
                accessorFn: originalRow => originalRow?.valueDate || ''
            },
            {
                accessorKey: 'accountDBTR.name',
                header: 'Účet debet - meno',
                accessorFn: originalRow => originalRow?.accountDBTR?.name || ''

            },
            {
                accessorKey: 'accountDBTR.customName',
                header: 'Účet debet - vlastné meno',
                accessorFn: originalRow => originalRow?.accountDBTR?.customName || ''
            },
            {
                accessorKey: 'accountDBTR.iban',
                header: 'Účet debet - IBAN',
                accessorFn: originalRow => originalRow?.accountDBTR?.iban || ''
            },
            {
                accessorKey: 'accountCDTR.name',
                header: 'Účet kredit - meno',
                accessorFn: originalRow => originalRow?.accountCDTR?.name || ''

            },
            {
                accessorKey: 'accountCDTR.iban',
                header: 'Účet kredit - IBAN',
                accessorFn: originalRow => originalRow?.accountCDTR?.iban || ''
            },
            {
                accessorKey: 'accountCDTR.customName',
                header: 'Účet kredit - vlastné meno',
                accessorFn: originalRow => originalRow?.accountCDTR?.customName || ''

            },
            {
                accessorKey: 'transactionType',
                header: 'Typ transakcie',
                accessorFn: originalRow => originalRow?.transactionType || ''
            },
            {
                accessorKey: 'amount',
                header: 'Suma',
                Footer: () => (
                    <Stack>
                        Celková suma:
                        <Box color="warning.main">
                            {data.content
                                .reduce(
                                    (acc, curr) =>
                                        acc
                                        + (curr.transactionType === 'DBIT' ? -1 : 1) * curr.amount,
                                    0,
                                )
                                ?.toLocaleString?.('en-US', {
                                    style: 'currency',
                                    currency: 'EUR',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2,
                                })}
                        </Box>
                    </Stack>
                ),
            },
            {
                accessorKey: 'vs',
                header: 'Variabilný symbol',
                accessorFn: originalRow => originalRow?.vs || ''
            },
            {
                accessorKey: 'ss',
                header: 'Špecifický symbol',
                accessorFn: originalRow => originalRow?.ss || ''
            },
            {
                accessorKey: 'ks',
                header: 'Konštantný symbol',
                accessorFn: originalRow => originalRow?.ks || ''

            },
            {
                accessorKey: 'description1',
                header: 'Popis 1',
                accessorFn: originalRow => originalRow?.description1 || ''
            },
            {
                accessorKey: 'description2',
                header: 'Popis 2',
                accessorFn: originalRow => originalRow?.description2 || ''
            },
            {
                accessorKey: 'haveCategory',
                header: 'Má kategóriu',
                filterVariant: 'checkbox',
                Cell: data => <div>{data.cell.getValue() ? 'Áno' : 'Nie'}</div>,
            },
            {
                header: 'Kategória',
                accessorFn: originalRow => {
                    originalRow?.category?.name
                },
            },
        ],
        [data],
    )

    useEffect(() => {
        setUserSettings({
            page: 'transactionTable',
            columnSizingState: columnSizing,
            columnFiltersState: columnFilters,
            columnPinningState: columnPinning,
            sortingState: sorting,
            visibilityState: columnVisibility,
            columnOrderState: columnOrder,
            globalFilter,
        });
    }, [
        columnSizing,
        columnFilters,
        columnPinning,
        sorting,
        columnVisibility,
        columnOrder,
        globalFilter,
    ])

    const getData = async () => {
        if (!data.content.length) {
            setComponentState(TableComponentStateEnum.LOADING);
        } else {
            setComponentState(TableComponentStateEnum.REFRESHING);
        }
        try {
            const _data = await transactionService.fetch({
                page: pagination.pageIndex,
                size: pagination.pageSize,
                columnFiltersState: columnFilters,
                globalFilter,
                sort: sorting.length > 0 ? sorting[0] : undefined,
                projection: 'transactionWithAccount',
            })
            _data.content.forEach((p) => {
                if (p.category == null) {
                    p.category = {
                        name: undefined,
                        internalTransaction: false,
                        priority: 0,
                        links: [],
                        condition: '',
                    }
                }
            })
            setData(_data)
            setComponentState(TableComponentStateEnum.SUCCESS);
        } catch (e) {
            setComponentState(TableComponentStateEnum.ERROR);
            console.error(e)
            return
        }

        if (refreshTime !== null && refreshTime !== undefined && refreshTime > 0) {
            timeout = setTimeout(() => getData(), refreshTime)
        }
    }

    useEffect(() => {
        getData().then()
    }, [
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
    ])

    useEffect(() => {
        return () => {
            clearTimeout(timeout)
        }
    }, [])

    useEffect(() => {
        const fetchUserAppSettings = () => {
            userAppSettingService
                .fetch({page: 'transactionTable'})
                .then((saveState) => {
                    setColumnPinning(saveState.columnPinningState)
                    setColumnSizing(saveState.columnSizingState)
                    setColumnVisibility(saveState.visibilityState)
                    setColumnFilters(saveState.columnFiltersState)
                    setSorting(saveState.sortingState)
                    setColumnOrder(saveState.columnOrderState)
                    setGlobalFilter(saveState.globalFilter)
                })
        }
        fetchUserAppSettings()
    }, [])

    return (
        <div>
            <MaterialReactTable
                enableStickyHeader
                enableStickyFooter
                muiTableContainerProps={{sx: {maxHeight: '65vh'}}}
                enableColumnResizing
                columnResizeMode="onChange"
                columns={columns}
                data={data.page.totalElements > 0 ? data.content : []}
                initialState={{
                    showColumnFilters: false,
                    density: 'compact',
                }}
                manualFiltering
                manualPagination
                manualSorting
                muiToolbarAlertBannerProps={
                    componentState === TableComponentStateEnum.ERROR
                        ? {
                            color: 'error',
                            children: 'Error loading data',
                        }
                        : undefined
                }
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={data.page.totalElements}
                state={{
                    columnFilters,
                    globalFilter,
                    isLoading: componentState === TableComponentStateEnum.LOADING,
                    pagination,
                    showAlertBanner: componentState === TableComponentStateEnum.ERROR,
                    showProgressBars: componentState === TableComponentStateEnum.REFRESHING,
                    sorting,
                    columnPinning,
                    columnSizing,
                    columnVisibility,
                    columnOrder,
                }}
                enablePinning
                onColumnPinningChange={setColumnPinning}
                onColumnSizingChange={setColumnSizing}
                onColumnVisibilityChange={setColumnVisibility}
                onColumnOrderChange={setColumnOrder}
                enableColumnOrdering
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                muiPaginationProps={{
                    rowsPerPageOptions: [50, 100, 200, 1000],
                }}
            ></MaterialReactTable>
        </div>
    )
}
