import React from 'react'
import { LoadingOverlay } from './LoadingOverlay'

export const Box: React.FC<{
  title: string | React.ReactElement | React.ReactElement[]
  actions?: string | React.ReactElement | React.ReactElement[]
  children: React.ReactElement
  loaded?: boolean
  isTable?: boolean
}> = ({ title, children, actions, loaded = true, isTable = false }) => {
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title" style={{ float: 'left' }}>
          {title}
        </h3>
        <div style={{ float: 'right' }}>{actions}</div>
      </div>
      <div
        className="card-body"
        style={
          isTable
            ? {
                padding: '0px 1.25rem 1.25rem',
              }
            : {}
        }
      >
        {children}
      </div>
      <LoadingOverlay loaded={loaded}></LoadingOverlay>
    </div>
  )
}
