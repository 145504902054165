import KeyboardIcon from '@mui/icons-material/Keyboard'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import RefreshIcon from '@mui/icons-material/Refresh'
import {Alert, Box, IconButton, Snackbar, Stack, Tooltip} from '@mui/material'
import type {
    ColumnFiltersState,
    ColumnOrderState,
    ColumnPinningState,
    ColumnSizingState,
    RowSelectionState,
    SortingState,
    VisibilityState,
} from '@tanstack/react-table'
import {MaterialReactTable, MRT_ColumnDef} from 'material-react-table'
import React, {useEffect, useMemo, useState} from 'react'
import {useRecoilState} from "recoil";
import {userSettingsState} from "../../../atoms/modelAtoms";
import {ModalCard} from '../../../components/ModalCard'
import {TableComponentStateEnum} from "../../../enums/TableComponentStateEnum";
import type {ContentWrapperWithPage} from '../../../models/ContentWrapperWithPage'
import type {ReceiptModel} from '../../../models/ReceiptModel'
import ReceiptService from '../../../services/ReceiptService'
import UserAppSettingService from '../../../services/UserAppSettingService'
import {ManualCodeComponent} from './ManualCodeComponent'
import {QRCodeComponent} from './QRCodeComponent'

export const ReceiptTableComponent: React.FC<{
    refreshTime?: number
    onRowSelectionChange: any
}> = ({
          refreshTime,
          onRowSelectionChange,
      }) => {
    const [data, setData] = useState<ContentWrapperWithPage<ReceiptModel>>({
        page: {
            totalElements: 0,
            number: 0,
            totalPages: 0,
            size: 0,
        },
        content: [],
    })

    const [userSettings, setUserSettings] = useRecoilState(userSettingsState);
    const [componentState, setComponentState] = useState<TableComponentStateEnum>(TableComponentStateEnum.LOADING)

    const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
    const [scan, setScan] = useState(false)
    const [manualScan, setManualScan] = useState(false)
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [globalFilter, setGlobalFilter] = useState('')
    const [sorting, setSorting] = useState<SortingState>([])
    const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
        left: [],
        right: [],
    })
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
    const [columnSizing, setColumnSizing] = useState<ColumnSizingState>({})
    const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([])

    const [snackOpen, setSnackOpen] = useState(false)
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100,
    })
    const receiptService: ReceiptService = new ReceiptService()
    const userAppSettingService: UserAppSettingService
        = new UserAppSettingService()

    const columns = useMemo<MRT_ColumnDef<ReceiptModel>[]>(
        () => [
            {
                accessorKey: 'receiptOrganization.name',
                header: 'Názov obchodu',
            },
            {
                accessorKey: 'issueDate',
                header: 'Dátum',
            },
            {
                accessorKey: 'totalPrice',
                header: 'Celková cena',
                Footer: () => (
                    <Stack>
                        Celková suma:
                        <Box color="warning.main">
                            {data.content
                                .reduce((acc, curr) => acc + curr.totalPrice, 0)
                                ?.toLocaleString?.('en-US', {
                                    style: 'currency',
                                    currency: 'EUR',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2,
                                })}
                        </Box>
                    </Stack>
                ),
            },
        ],
        [data],
    )

    useEffect(() => {
        setUserSettings({
            page: 'receiptTable',
            columnSizingState: columnSizing,
            columnFiltersState: columnFilters,
            columnPinningState: columnPinning,
            sortingState: sorting,
            visibilityState: columnVisibility,
            columnOrderState: columnOrder,
            globalFilter,
        });
    }, [
        columnSizing,
        columnFilters,
        columnPinning,
        sorting,
        columnVisibility,
        columnOrder,
        globalFilter,
    ])

    const getData = async () => {
        if (!data.content.length) {
            setComponentState(TableComponentStateEnum.LOADING)
        } else {
            setComponentState(TableComponentStateEnum.REFRESHING)
        }

        try {
            const _data = await receiptService.fetch({
                page: pagination.pageIndex,
                size: pagination.pageSize,
                columnFiltersState: columnFilters,
                globalFilter,
                sort: sorting.length > 0 ? sorting[0] : undefined,
                projection: 'receiptPreview',
            })

            setData(_data)
            setComponentState(TableComponentStateEnum.SUCCESS);
        } catch (e) {
            setComponentState(TableComponentStateEnum.ERROR);
            console.error(e)
            return
        }

        if (refreshTime !== null && refreshTime !== undefined && refreshTime > 0) {
            setTimeout(() => getData(), refreshTime)
        }
    }

    useEffect(() => {
        getData().then()
    }, [
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
    ])

    useEffect(() => {
        const fetchUserAppSettings = () => {
            userAppSettingService
                .fetch({page: 'receiptTable'})
                .then((saveState) => {
                    setColumnPinning(saveState.columnPinningState)
                    setColumnSizing(saveState.columnSizingState)
                    setColumnVisibility(saveState.visibilityState)
                    setColumnFilters(saveState.columnFiltersState)
                    setSorting(saveState.sortingState)
                    setColumnOrder(saveState.columnOrderState)
                    setGlobalFilter(saveState.globalFilter)
                })
        }
        fetchUserAppSettings()
    }, [])

    return (
        <div>
            <ModalCard
                style={{minHeight: '400px'}}
                title={'Skenovanie bločku'}
                onClose={() => setScan(false)}
                open={scan}
            >
                <QRCodeComponent
                    onReadCompleted={() => {
                        setScan(false);
                        getData().then();
                    }}
                />

            </ModalCard>

            <ModalCard
                style={{minHeight: '200px'}}
                title={'Manuálne získanie bločku'}
                onClose={() => setManualScan(false)}
                open={manualScan}
            >
                {manualScan
                    ? (
                        <ManualCodeComponent
                            onReadCompleted={async () => {
                                await setManualScan(false)
                                await getData()
                            }}
                        />
                    )
                    : (
                        <div></div>
                    )}
            </ModalCard>

            <MaterialReactTable
                enableStickyHeader
                enableStickyFooter
                muiTableContainerProps={{sx: {maxHeight: '65vh'}}}
                enableColumnResizing
                columnResizeMode="onChange"
                columns={columns}
                data={data?.page?.totalElements > 0 ? data.content : []}
                initialState={{
                    showColumnFilters: false,
                    density: 'compact',
                }}
                manualFiltering
                manualPagination
                manualSorting
                muiToolbarAlertBannerProps={
                    componentState === TableComponentStateEnum.ERROR
                        ? {
                            color: 'error',
                            children: 'Error loading data',
                        }
                        : undefined
                }
                renderTopToolbarCustomActions={() => (
                    <div style={{display: 'inline'}}>
                        <Tooltip arrow title="Refresh Data">
                            <IconButton onClick={() => getData()}>
                                <RefreshIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow title="Scan bloček">
                            <IconButton
                                data-toggle="modal"
                                data-target="#scanReceipt"
                                onClick={() => setScan(true)}
                            >
                                <QrCodeScannerIcon/>
                            </IconButton>
                        </Tooltip>

                        <Tooltip arrow title="Manuálne zadanie bločku">
                            <IconButton
                                data-toggle="modal"
                                data-target="#manualScanReceipt"
                                onClick={() => setManualScan(true)}
                            >
                                <KeyboardIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={data?.page?.totalElements || 0}
                enablePinning
                onColumnPinningChange={setColumnPinning}
                onColumnSizingChange={setColumnSizing}
                onColumnVisibilityChange={setColumnVisibility}
                onColumnOrderChange={setColumnOrder}
                state={{
                    columnFilters,
                    globalFilter,
                    isLoading: componentState === TableComponentStateEnum.LOADING,
                    pagination,
                    showAlertBanner: componentState === TableComponentStateEnum.ERROR,
                    showProgressBars: componentState === TableComponentStateEnum.REFRESHING,
                    rowSelection,
                    sorting,
                    columnPinning,
                    columnSizing,
                    columnVisibility,
                    columnOrder,
                }}
                defaultColumn={{
                    minSize: 20,
                    maxSize: 900,
                    size: 100,
                }}
                enableColumnOrdering
                muiTableBodyRowProps={({row}) => ({
                    onClick: () => {
                        setRowSelection(prev => ({
                            [row.id]: !prev[row.id],
                        }))
                        onRowSelectionChange(row.original.id)
                    },
                    selected: rowSelection[row.id],
                    sx: {
                        cursor: 'pointer',
                    },
                })}
                muiPaginationProps={{
                    rowsPerPageOptions: [50, 100, 200],
                }}
            ></MaterialReactTable>
        </div>
    )
}
